import {Component} from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles'

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';

import TextField from '@mui/material/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const styles = (theme) => createStyles({

    root: {
        height: "100vh",
        width: "100vw",
        paddingTop: "10%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    title: {
        fontFamily: "Nunito",
        fontWeight: 700,
        fontSize: "42px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "32px",
            marginTop: "100px",
            marginBottom: "10px"
        }    
    },
    filterinput: {
        backgroundColor: "white",
        width: "300px",
        height: "auto",
        borderRadius: "5px"
    },               
    instruction: {
        fontSize: "18px",
        margin: "20px 15px 20px 0",
    },
    list: {
        maxHeight: "54vh",
        maxWidth: "1200px",
        marginTop: 30,
        width: "95%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "space-evenly",
        overflowY: "scroll",
    },
    media: {
        height: "80px",
        [theme.breakpoints.down('sm')]: {
            height: 50
        }    
    },
    card: {
        transition: "1s all ease",
        border: "1px solid darkgray", 
        maxWidth: 200, 
        maxHeight: 300,
        margin: "6px",
        [theme.breakpoints.down('sm')]: {
            maxwidth: 30,
            maxHeight: 100,
            margin: 2
        }    
    },
    topictitle: {
        fontFamily: "Nunito",
        fontSize: "18px",
        minHeight: "60px",
    },
    description: {
        fontSize: "12px",
        height: "40px",
        overflow: "hidden",
    }
});

class PickSetForm extends Component {

    constructor(props) {
        super(props)
        this.state = { filterText: "" }
    }

    handleClick = (e) => {
        console.log(e.target)
        var topiccode = e.target.getAttribute("topiccode");
        if (!topiccode) return console.log("Something went wrong - nothing selected")
        console.log("Clicked on: "+topiccode)
        this.props.changeTopic(topiccode)
        this.props.showDiv("")
        setTimeout(()=>this.props.startCramming(), 2000);
    }

    handleHover = (e) => {
        var textcontent = e.target.getAttribute("outcry");
        this.props.appSpeak(textcontent)
    }

    handleFilterChange = (e) => {
        this.setState({ filterText: e.target.value })
    }

    render() {
        var { classes } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.title}>
                    Select your Topic
                </div>
                <TextField
                    className={classes.filterinput}
                    type="text" 
                    name="filterTerm"
                    onChange={this.handleFilterChange}
                    value={this.state.filterText}
                    placeholder="Enter a text to filter"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment>
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                            </InputAdornment>
                        )
                    }}
                /> 
                <div className={classes.list}>
                    {this.props.fullTopics.map((t,i) => {
                        if (t.topictitle.toUpperCase().indexOf(
                                this.state.filterText.toUpperCase()) >-1) {
                            return (
                                <Card className={classes.card}
                                        variant="outlined"
                                        key={i}
                                        outcry={t.topictitle}
                                        topiccode={t.topiccode}
                                        onMouseEnter={this.handleHover}
                                        onClick={this.handleClick}                                
                                    >
                                    <CardMedia className={classes.media}
                                        component="img"
                                        alt={t.topictitle}
                                        outcry={t.topictitle}
                                        topiccode={t.topiccode}
                                        image={t.setimage}
                                    />
                                    <CardContent height="40" 
                                                 topiccode={t.topiccode}>
                                        <Typography gutterBottom 
                                                    variant="h6" 
                                                    component="div">
                                            <div className={classes.topictitle}
                                                    topiccode={t.topiccode}
                                                >
                                                {t.topictitle}
                                            </div>
                                        </Typography>
                                        <Typography variant="body3" color="text.secondary">
                                            <div className={classes.description}
                                                 topiccode={t.topiccode}
                                                >
                                                {t.description}
                                            </div>
                                        </Typography>
                                    </CardContent>
                                    <CardActions disableSpacing>
                                        <Button size="small">Cram</Button>
                                        <Button size="small">Edit</Button>
                                        <IconButton aria-label="add to favorites">
                                            <FavoriteIcon />
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            )
                        }
                    })}
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(PickSetForm);