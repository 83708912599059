import {Component} from 'react';

import { Button } from '@material-ui/core';

import { withStyles, createStyles } from '@material-ui/core/styles'

const styles = (theme) => createStyles({
    root: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    title: {
        marginBottom: "20px",
        fontSize: "28px",
        fontWeight: "bold",
        [theme.breakpoints.down('sm')]: {
            fontSize: "18px"
        }    
    },
    description1: {
        margin: "15px",
        fontSize: "20px"
    },
    description2: {
        marginBottom: "15px",
        fontSize: "18px"
    },
    description3: {
        marginBottom: "15px",
        fontSize: "18px"
    },
    buttoncontainer: {
        margin: "40px"
    }
})

class Congratulations extends Component {

    handleNextSet = () => {
        this.props.startCramming();
    }

    render() {
        const { classes } = this.props;
        console.log(this.props.currentSet)
        var itemslearnt = this.props.currentSet.reduce(
            (p, i) => p + ((Number(i.corrects)>0 && !+i.reported) ? 1 : 0), 0)

        var attempts = this.props.currentSet.reduce(
            (p, i) => p + Number(i.attempts), 0)

        var absorption = 13 - 3*attempts/itemslearnt;
        if (isNaN(absorption)) absorption = 0;
        absorption = Math.round(absorption*10)/10+1;

        return (
            <div className={classes.root}>
                <div className={classes.title}>
                    Congratulations, {this.props.currentCrammer.firstname}!
                </div>
                <div className={classes.title}>
                    You did another set of {this.props.currentTopic.topictitle}!
                </div>
                <div className={classes.description1}>
                    You learnt {itemslearnt} new items.
                </div>
                <div className={classes.description2}>
                    You used {attempts} attempts to do so.
                </div>
                <div className={classes.description3}>
                    Your absorption index was {absorption} out of 10.
                </div>
                <div className={classes.buttoncontainer}>
                    <Button 
                        variant="contained"
                        color="primary"
                        onClick={this.handleNextSet}>
                            On to the next set
                    </Button>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(Congratulations);