import React, { Component } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles'

import CssBaseline from '@mui/material/CssBaseline';

import { BottomNavigation, Button } from '@material-ui/core';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import FeedIcon from '@mui/icons-material/Feed';
import Paper from '@mui/material/Paper';
import { render } from '@testing-library/react';

import { Link as Scroll } from 'react-scroll';

const styles = (theme) => createStyles({
    footer: {
        position: 'fixed', 
        zIndex: 500,
        backgroundColor: "transparent",
        color: "white",
        bottom: 0, 
        left: 0, 
        right: 0
    },
    navigator: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    score: {
        margin: "0 10% 0 10%",
        fontSize: "1rem",
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.6rem",
        }
    },
    button: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 12,
        [theme.breakpoints.down('sm')]: {
            fontSize: "8px"
        }
    }
});

class Footer extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { classes } = this.props;

        var loginlabel = (this.props.currentCrammer) 
            ? this.props.currentCrammer.username
            : "Log In"
    
        var picksetlabel = (this.props.currentTopic) 
            ? this.props.currentTopic.topictitle
            : "Pick a Set"
    
        return <div id="footer">
            <Paper className={classes.footer} elevation={3}>
                <CssBaseline />
                <BottomNavigation 
                    className={classes.navigator} 
                    >
                    {this.props.currentCrammer && 
                        <div className={classes.score}>
                            {`Score: ${this.props.currentCrammer.score}`}
                        </div>
                    }
                    <Scroll to="login" smooth={true}>
                        <Button onClick={() => this.props.showDiv("PasswordForm")}>
                            <div className={classes.button}>                            
                                {loginlabel} <PersonOutlineIcon/>
                            </div>
                        </Button>
                    </Scroll>
                    <Scroll to="actionform" smooth={true}>
                    <Button onClick={() => this.props.showDiv("PickSetForm")}>
                        <div className={classes.button}>
                            {picksetlabel} <FeedIcon/>
                        </div>            
                    </Button>
                    </Scroll>
                    {this.props.currentCrammer && 
                        <div className={classes.score}>
                            {`Words: ${this.props.currentCrammer.corrects}`}
                        </div>
                    }
                </BottomNavigation>
            </Paper>
        </div>
    }
}

export default withStyles(styles)(Footer);