import { Component } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles'

import { Link as Scroll } from 'react-scroll';

import { TextField } from '@mui/material';

import { styled } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import MuiButton from "@material-ui/core/Button";

const Button = styled(MuiButton)(spacing);


const styles = (theme) => createStyles({
    root: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    title: {
        marginBottom: "25px",
        fontFamily: "'Nunito', cursive",
        fontSize: "3rem",
        [theme.breakpoints.down('sm')]: {
            fontSize: "2.0rem"
        }
    },
    inputs: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "75%",
        margin: "0 0 0 -100px",
        [theme.breakpoints.down('sm')]: {
            width: "300px",
            margin: "0 0 0 -50px",
        }
    },
    input: {
        width: "400px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "100px"
        }
    },
    next: {
        width: "60%",
        height: "80px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        }
    },
    label: {
        fontFamily: "Nunito",
        fontSize: "20px",
        color: "black",
        textAlign: "right",
        paddingRight: "25px",
    },
    buttoncontainer: {
        margin: "40px 0 0 0",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        flexWrap: "wrap",
        padding: "10px 0 10px",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        }
    },
    button: {
        margin: "5px"
    }
});

class PasswordForm extends Component {

    constructor(props) {
        super(props)
        this.state = { loginUsername: "",
                       loginTopicTitle: "",
                       loginPassword: "",
                       passwordError: false,
                       crammerError: false
        }
    }

    componentDidMount() {
        var loginUsername = "";
        var loginPassword = "";
        var loginTopicTitle = "";
        if (this.props.currentCrammer) {
            loginUsername = this.props.currentCrammer.username;
        }
        if (this.props.currentTopic) {
            loginTopicTitle = this.props.currentTopic.topictitle;
        }
        this.setState( { loginUsername, loginPassword, loginTopicTitle } )
    }

    handleKeyUp = (e) => {
        var n = e.target.name
        if (n==="loginPassword") {
            if (e.key === 'Enter') {
                this.handleProceed()
            }    
        }
    }

    handleChange = (e) => {
        var n = e.target.name
        var v = e.target.value
        if (n==="loginUsername") {
            v = v.slice(0,6).replace(" ", "").toLowerCase();
            this.setState({ passwordError : false, crammerError : false })
        }
        if (n==="loginPassword") {
            v = v.toLowerCase();
            this.setState({ passwordError : false })
        }
        this.setState(st => 
            st[n] = v);
    }

    handleTopic = (e) => {
        this.props.changeTopic(e.target.value)
    }

    handleNewUser = (e) => {
        this.props.showDiv("NewCrammerForm")
    }

    handleProceed = (e) => {
        var crammer = this.props.crammers.find(c => 
            c.username===this.state.loginUsername
        )
        var bok = true;
        if (crammer && crammer.password)
            bok = (crammer.password.toLowerCase()===
                   this.state.loginPassword.toLowerCase())
        if (crammer) {
            if (bok) {
                this.props.showDiv("")
                this.props.setCurrentCrammer(crammer.username);
                setTimeout(() => {this.props.startCramming()}, 1000)
            } else {
                this.setState( { passwordError : true } )
            }
        } else {
            this.setState( { crammerError : true } )
        }
    }
    
    render() {
        var { classes } = this.props;
        return (
            <div className={classes.root} id="login">
                <div className={classes.title}>
                    Log in to Cram
                </div>
                <div className={classes.inputs}>
                    <div className={classes.next}>
                        <span className={classes.label}>
                            Username
                        </span>
                        <TextField
                            className={classes.input}
                            type="text" 
                            error={this.state.crammerError}
                            helperText={this.state.crammerError ? "Incorrect username" : ""}
                            name="loginUsername"
                            onChange={this.handleChange}
                            value={this.state.loginUsername}
                            placeholder="Your Username"/> 
                    </div>
                    <div className={classes.next}>
                        <span className={classes.label}>
                            Password
                        </span>
                        <TextField
                            className={classes.input}
                            type="text" 
                            error={this.state.passwordError}
                            helperText={this.state.passwordError ? "Incorrect password" : ""}
                            name="loginPassword"
                            onChange={this.handleChange}
                            onKeyUp={this.handleKeyUp}
                            value={this.state.loginPassword}
                            placeholder="Your Password"/> 
                    </div>
                </div>
                <div className={classes.buttoncontainer}>
                    <Button variant="contained" 
                            color="primary"
                            className={classes.button}
                            size="large"
                            m={1}
                            onClick={this.handleProceed}>
                                Proceed to Cram
                    </Button>
                    <Scroll to="actionform" smooth={true}>
                        <Button variant="contained" 
                                color="primary"
                                className={classes.button}
                                size="large"
                                m={1}
                                onClick={this.handleNewUser}>
                                    Create new user
                        </Button>
                    </Scroll>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(PasswordForm);