const reasons = [
    {
        title: "Make friends abroad?",
        description: "Learning a new language is fun. You will make friends, laugh and enjoy.",
        imageUrl: "https://www.ef.com/blog/wp-content/uploads/2018/06/How_to_make_friends_abroad_blog_hero.jpg",
        direction: "left",
        time: 1500
    },
    {
        title: "Want good grades?",
        description: "School and study can be intense. Why not learn much smarter and quicker?",
        imageUrl: "https://www.usnews.com/dims4/USNEWS/897fb9b/2147483647/thumbnail/640x420/quality/85/?url=http%3A%2F%2Fmedia.beam.usnews.com%2Fe7%2F76%2Faa5f5ae74348a89fdbef6aba8964%2F140312-excellentgrade-stock.jpg",
        direction: "middle",
        time: 1500
    },
    {
        title: "An international job?",
        description: "In a world that is increasingly global, employers like to see languages on your CV.",
        imageUrl: "http://blog.internations.org/wp-content/uploads/2015/11/Kick-Off-Your-International-Job-Search-Pic-2.jpg",
        direction: "right",
        time: 1500
    }
];

export default reasons;
