import {Component} from 'react';
import './AnalysisForm.css';

const { v4: uuidv4 } = require('uuid');

class AnalysisForm extends Component {

    constructor(props) {
        super(props)
        this.state = { loginUsername: "",
                       loginTopicTitle: "",
                       loginAnalysis: "",
                       AnalysisError: false,
                       crammerError: false
        }
    }

    componentDidMount() {
        var loginUsername = "";
        var loginAnalysis = "";
        var loginTopicTitle = "";
        if (this.props.currentCrammer) {
            loginUsername = this.props.currentCrammer.username;
        }
        if (this.props.currentTopic) {
            loginTopicTitle = this.props.currentTopic.topictitle;
        }
        this.setState( { loginUsername, loginAnalysis, loginTopicTitle } )
    }

    
    render() {
        var formclass = "AnalysisForm"
        var tables = 
            <div>
                {this.props.fullTopics.map(t => {
                    var topicProgress = this.props.fullProgress
                        .filter(i => i.topiccode===t.topiccode)
                    if (topicProgress.length) {
                        return <div className="AnalysisForm-table"
                            key={uuidv4()}>
                                <div className="AnalysisForm-topiccode">{t.topiccode}</div>
                                <div className="AnalysisForm-topictitle">{t.topictitle}</div>
                            <table>
                                <thead>
                                    <tr>
                                        <th className="limit">Attempts</th>
                                        <th className="limit">Corrects</th>
                                        <th>Question</th>
                                        <th>Answer</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        topicProgress.map(i => (
                                        <tr key={uuidv4()} className={
                                            (i.reported==="1") ? "AnalysisForm-reported": ""
                                        }>
                                            <td className="limit">{i.attempts}</td>
                                            <td className="limit">{i.corrects}</td>
                                            <td>{i.question}</td>
                                            <td>{i.answer}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                })}                
            </div>

        return (
            <div className={formclass}>
                <div className="AnalysisForm-title">
                    {`You have gone through ${this.props.fullProgress.length} words so far in this set:`}
                </div>
                <hr/>
                {tables}
            </div>
        )
    }
}

export default AnalysisForm;