import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link as Scroll } from 'react-scroll';

import { IconButton } from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const { v4: uuidv4 } = require('uuid');

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            width: "90%",
            fontSize: "1.5rem"
        }
    },
    title: {
        color: "black",
        fontFamily: "Nunito",
        fontSize: "3rem",
        textAlign: "center",
        width: "80%",
        [theme.breakpoints.down('sm')]: {
            width: "80%",
            fontSize: "1.5rem"
        }
    },
    blue: {
        color: "blue",
    },
    table: {
        marginTop: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        width: "60%",
        [theme.breakpoints.down('sm')]: {
            height: "50vh",
            marginTop: "20px",
            width: "95%",
        }
    },
    card: {
        width: "70px",
        height: "75px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        margin: "25px 25px 10px 25px",
        transition: "1s all ease",
        "&:hover" : {
            cursor: "pointer",
            transform: "scale(1.5)"
        },
        [theme.breakpoints.down('sm')]: {
            width: "40px",
            height: "45px",
            margin: "0px 10px 5px 10px",
        }
    },
    img: {
        minHeight: 40,
        width: "100%",
        [theme.breakpoints.down('sm')]: {
            margin: "0px",
            minHeight: 30
        }
    },
    cardTitle: {
        fontSize: "18px",
        marginTop: 5,
        fontWeight: "bold",
        [theme.breakpoints.down('sm')]: {
            marginTop: "4px",
            fontSize: "11px"
        }
    },
    goDown: {
        fontSize: "5rem",
        color: "blue",
        [theme.breakpoints.down('sm')]: {
            fontSize: "4rem"
        }
    }
}));

var topics = [
    ["French", "https://www.sciencekids.co.nz/images/pictures/flags96/France.jpg"],
    ["Italian", "https://www.sciencekids.co.nz/images/pictures/flags96/Italy.jpg"],
    ["Danish", "https://www.sciencekids.co.nz/images/pictures/flags96/Denmark.jpg"],
    ["German", "https://www.sciencekids.co.nz/images/pictures/flags96/Germany.jpg"],
    ["Ukranian", "https://www.sciencekids.co.nz/images/pictures/flags96/Ukraine.jpg"],
    ["Dutch", "https://www.sciencekids.co.nz/images/pictures/flags96/Netherlands.jpg"],
    ["Portuguese", "https://www.sciencekids.co.nz/images/pictures/flags96/Portugal.jpg"],
    ["Hebrew", "https://www.sciencekids.co.nz/images/pictures/flags96/Israel.jpg"],
    ["Spanish", "https://www.sciencekids.co.nz/images/pictures/flags96/Spain.jpg"],
    ["Norwegian", "https://www.sciencekids.co.nz/images/pictures/flags96/Norway.jpg"],
    ["Swedish", "https://www.sciencekids.co.nz/images/pictures/flags96/Sweden.jpg"],
    ["Finnish", "https://www.sciencekids.co.nz/images/pictures/flags96/Finland.jpg"],
    ["Indonesian", "https://www.sciencekids.co.nz/images/pictures/flags96/Indonesia.jpg"],
    ["Japanese", "https://www.sciencekids.co.nz/images/pictures/flags96/Japan.jpg"],
    ["Chinese", "https://www.sciencekids.co.nz/images/pictures/flags96/China.jpg"],
    ["Polish", "https://www.sciencekids.co.nz/images/pictures/flags96/Poland.jpg"],
    ["Hungarian", "https://www.sciencekids.co.nz/images/pictures/flags96/Hungary.jpg"],
    ["Greek", "https://www.sciencekids.co.nz/images/pictures/flags96/Greece.jpg"],
    ["Arabic", "https://www.sciencekids.co.nz/images/pictures/flags96/Morocco.jpg"],
    ["Rumanian", "https://www.sciencekids.co.nz/images/pictures/flags96/Romania.jpg"],
]

export default function WhatToLearn() {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.root} id="what-to-learn">
                <div className={classes.title}>Which <span className={classes.blue}>language</span> would you like to learn?</div>
                <div className={classes.table}>
                    {topics.map(t => 
                        <Scroll to="login" smooth={true}>
                            <div key={uuidv4()} className={classes.card}>
                                <img className={classes.img} src={t[1]} alt="" />
                                <div className={classes.cardTitle}>{t[0]}</div>
                            </div>
                        </Scroll>
                    )}
                </div>
                <Scroll to="login" smooth={true}>
                    <IconButton>
                        <ExpandMoreIcon className={classes.goDown} />
                    </IconButton>
                </Scroll>
            </div>
        </div>
    )
}