import {Component} from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles'

import { Link as Scroll } from 'react-scroll';

import { TextField } from '@mui/material';

import { styled } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import MuiButton from "@material-ui/core/Button";

const Button = styled(MuiButton)(spacing);

const styles = (theme) => createStyles({
    root: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    }, 
    title: {
        fontFamily: "Nunito",
        fontSize: "2.5rem",
        margin: "10px 0 0 0",
        [theme.breakpoints.down('sm')]: {
            fontSize: "1.5rem",
        },
    },
    inputs: {
        width: "70%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
        color: "black",
        padding: "10px",
        [theme.breakpoints.down('sm')]: {
            width: "90%",
            padding: "5px",
        },
    },
    input: {
        textAlign: "center",
        margin: "10px",
        width: "50%",
        padding: "5px",
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            padding: 0,
            width: "60%",
        },
    },
    next: {
        marginBottom: "5px",
        display: "flex",
        alignItems: "center",
        textAlign: "left",
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
        },
    },
    label: {
        fontFamily: "Nunito",
        fontSize: "1.2rem",
        width: "260px",
        margin: "20px 0 0 15px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.7rem",
            width: "200px"
        },
    },
    buttoncontainer: {
        margin: "30px",
        display: "flex",
        [theme.breakpoints.down('sm')]: {
            margin: "20px",
        },
    }
});


class NewCrammerForm extends Component {

    constructor(props) {
        super(props)
        var currentCrammer = this.props.currentCrammer
        var username = (currentCrammer) ? currentCrammer.username : "";
        var firstname = (currentCrammer) ? currentCrammer.firstname : "";
        var alterego = (currentCrammer) ? currentCrammer.alterego : "";
        var password = (currentCrammer) ? currentCrammer.password : "";
        var email = (currentCrammer) ? currentCrammer.email : "";
        var mobile = (currentCrammer) ? currentCrammer.mobile : "";
        var attempts = (currentCrammer) ? currentCrammer.attempts : 0;
        var corrects = (currentCrammer) ? currentCrammer.corrects : 0;
        this.state = { newCrammer : {
                            username, firstname,
                            alterego, password,
                            email, mobile,
                            attempts, corrects,
                            usernameError: false,
                            firstnameError: false,
                            alteregoError: false,
                            passwordError: false,
                      }}
    }

    handleSubmit = (e) => {
        var bok = [];
        if (!this.state.newCrammer.username) bok.push("username");
        if (!this.state.newCrammer.firstname) bok.push("firstname");
        if (!this.state.newCrammer.password) bok.push("password");
        if (!this.state.newCrammer.alterego) bok.push("alterego");
        if (bok.length>0) {
            this.setState({
                usernameError: bok.indexOf("username") > 0,
                firstnameError: bok.indexOf("firstname") > 0,
                alteregoError: bok.indexOf("alterego") > 0,
                passwordError: bok.indexOf("password") > 0
            })
        } else {
            this.props.addCrammer(this.state.newCrammer);
            this.setState({ newCrammer : {
                username: "", 
                firstname: "", 
                alterego: "", 
                password: "", 
                email: "",
                mobile: ""
            }})
        }
    }

    handleExit = (e) => {
        this.props.showDiv("PasswordForm")
    }

    handleDelete = (e) => {
        this.props.deleteCrammer(this.props.currentCrammer.username);
        this.props.showDiv("CrammerCarousel")
    }

    handleChange = (e) => {
        var n = e.target.name
        var v = e.target.value
        if (n==="username") v = v.slice(0,6).replace(" ", "").toLowerCase();
        if (n==="password") v = v.slice(0,6).replace(" ", "").toLowerCase();
        this.setState( {
                usernameError: false,
                passwordError: false,
                alteregoError: false,
                firstnameError: false
            }, this.setState((st) => st.newCrammer[n] = v)
        );
    }

    render() {
        var { classes } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.title}>
                {(this.props.currentCrammer)
                        ? `Amend your Details`
                        : `Welcome to Wordcrammer!`
                    }
                </div>
                <div className={classes.inputs}>
                    <div className={classes.three}>
                        <div className={classes.next}>
                            <TextField 
                                error={this.state.usernameError}
                                variant="filled"
                                type="text" 
                                name="username"
                                margin="dense"
                                username="username" 
                                onChange={this.handleChange}
                                value={this.state.newCrammer.username}
                                placeholder="Your Unique Username"/> 
                                    <span className={classes.label}>
                                        e.g. "abcde"
                                    </span>                                
                        </div>
                        <div className={classes.next}>
                            <TextField 
                                error={this.state.passwordError}
                                variant="filled"
                                type="text" 
                                name="password"
                                margin="dense"
                                password="password" 
                                onChange={this.handleChange}
                                value={this.state.newCrammer.password}
                                placeholder="Your Password"/> 
                                    <span className={classes.label}>
                                        e.g. "abcde"
                                    </span>                                
                        </div>
                        <div className={classes.next}>
                            <TextField 
                                error={this.state.firstnameError}
                                variant="filled"
                                type="text" 
                                name="firstname"
                                margin="dense"
                                firstname="firstname" 
                                onChange={this.handleChange}
                                value={this.state.newCrammer.firstname}
                                placeholder="Your First Name"/> 
                                    <span className={classes.label}>
                                        e.g. "Hans"
                                    </span>                                
                        </div>
                    </div>
                    <div className={classes.three}>
                        <div className={classes.next}>
                            <TextField 
                                error={this.state.alteregoError}
                                variant="filled"
                                type="text" 
                                name="alterego"
                                margin="dense"
                                alterego="alterego" 
                                onChange={this.handleChange}
                                value={this.state.newCrammer.alterego}
                                placeholder="Your Alter Ego"/> 
                                    <span className={classes.label}>
                                        e.g. "The Pianist"
                                    </span>                                
                        </div>
                        <div className={classes.next}>
                            <TextField 
                                variant="filled"
                                type="email" 
                                name="email"
                                margin="dense"
                                value={this.state.newCrammer.email}
                                onChange={this.handleChange}
                                placeholder="Your Email Address"/>
                                    <span className={classes.label}>
                                        e.g. "hans@wxs.nl"
                                    </span>                                
                        </div>
                        <div className={classes.next}>
                            <TextField 
                                variant="filled"
                                type="text" 
                                name="mobile"
                                margin="dense"
                                value={this.state.newCrammer.mobile}
                                onChange={this.handleChange}
                                placeholder="Your Phone Number"/>
                                    <span className={classes.label}>
                                        e.g. "+44 75 3366 4463"
                                    </span>                                
                        </div>
                    </div>
                </div>
                <div className={classes.buttoncontainer}>
                    {(this.props.currentCrammer) && 
                        <Scroll to="login" smooth={true}>
                            <Button variant="contained" 
                                    size="large"
                                    color="secondary"
                                    mx={1}
                                    onClick={this.handleDelete}>
                                        Delete User
                            </Button>
                        </Scroll>}
                    <Scroll to="login" smooth={true}>    
                        <Button variant="contained" 
                            color="primary"
                            size="large"
                            mx={1}
                            onClick={this.handleExit}>
                                Cancel
                        </Button>
                    </Scroll>
                    <Scroll to="actionform" smooth={true}>    
                        <Button variant="contained" 
                                size="large"
                                color="primary"
                                mx={1}
                                onClick={this.handleSubmit}>
                                    {(!this.props.currentCrammer)
                                    ? `Add User`
                                    : `Amend User`}
                        </Button>
                    </Scroll>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(NewCrammerForm);