const synth = window.speechSynthesis;

const speak = async (whattosay, voices, 
    voicelang = "en-GB", rate = 1, pitch = 1) => {
        await new Promise(function(resolve, reject) {
            console.log("Instruction to speak: "+whattosay)
            if (!synth.speaking) {
                const speakText = new SpeechSynthesisUtterance(whattosay)
                speakText.onend = e => { resolve(true)}
                speakText.onerror = e => { console.log("something went wrong: "+e.error)}
                var voice; 
                // var englishVoice;
                if (voices) {
                    voices.forEach(v => {
                        if (v.lang.split("-")[0] === voicelang.split("-")[0]) voice = v;
                        if (v.lang === voicelang) voice = v;
                        
                        //if (v.lang === "en-GB") englishVoice = v;    
                    })
                    if (!voice) {
                        console.log("Voice not found");
                        /*voice = englishVoice;
                        console.log("Voice set to: ", voice)*/
                    }
                    if (voice) {
                        speakText.voice = voice;
                        console.log("NOW SPEAKING IN:", speakText.voice.lang)
                        speakText.rate = rate;
                        console.log("Rate: "+rate)
                        speakText.pitch = pitch;
                        console.log("Pitch: "+pitch)
                        synth.speak(speakText)    
                    } else {
                        console.log(`No such voice "${voicelang}" found.`)
                    }    
                } else { console.log("No voices loaded at all.")}        
            }
        })
}

export const getVoices = async () => {
    const allVoicesObtained = new Promise(function(resolve, reject) {
        let voices = window.speechSynthesis.getVoices();
        if (voices.length !== 0) {
            resolve(voices);
        } else {
            window.speechSynthesis
              .addEventListener("voiceschanged", function() {
                voices = window.speechSynthesis.getVoices();
                resolve(voices);
            });
        }
    });
      
    return await allVoicesObtained;
}

export default speak;