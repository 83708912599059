import React, { Component } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles'

import { AppBar, IconButton, Toolbar, Collapse } from '@material-ui/core';

import { Link as Scroll } from 'react-scroll';

const { v4: uuidv4 } = require('uuid');

const styles = (theme) => createStyles({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    bar: {
        backgroundColor: "transparent"
    },
    wrapper: {
        width: "80%",
        display: "flex",
        justifyContent: "space-between",
        margin: "30px auto",
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            width: "95%"
        }
    },
    buttoncontainer: {
        width: "auto",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            position: "absolute",
            right: 0,
            top: 15,
            height: "auto",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-end",
        }
    },
    button: {
        margin: "3px",
        padding: "3px 40px",
        color: "slategray",
        fontFamily: "Nunito",
        fontSize: "1.5rem",
        cursor: "pointer",
        transition: "1s all ease",
        borderRadius: "5px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "1rem",
            padding: 0,
            color: "black"
        },
        "&:hover": {
            transform: "scale(1.7)",
            color: "black"
        }
    },
    title: {
        color: "black",
        fontFamily: "Nunito",
        fontSize: "2.5rem",
        [theme.breakpoints.down('sm')]: {
            fontSize: "1.5rem",
        },
    },
    slategray: {
        color: "slategray"
    },
    blue: {
        color: "blue"
    },
    footer: {
        position: 'fixed', 
        backgroundColor: "blue",
        color: "white",
        bottom: 0, 
        left: 0, 
        right: 0
    }
});

var menu = [
    { id : 0, onlyloggedin: false, section: "About", scroll: "about", link: ""},
    { id : 1, onlyloggedin: false, section: "History", scroll: "history", link: ""},
    { id : 2, onlyloggedin: false, section: "Log In", scroll: "login", link: "PasswordForm"},
    { id : 3, onlyloggedin: true, section: "Cram", scroll: "actioncontainer", link: "CramContainer"},
    { id : 4, onlyloggedin: true, section: "Topic", scroll: "actioncontainer", link: "PickSetForm"},
    { id : 5, onlyloggedin: true, section: "Analysis", scroll: "actioncontainer", link: "AnalysisForm"},
    { id : 6, onlyloggedin: true, section: "Upload", scroll: "actioncontainer", link: "EditSetForm"}
]

class Header extends Component {

    constructor(props) {
        super(props);
    }

    handleClick = (e) => {
        var link = e.target.getAttribute("link");
        if (link) this.props.showDiv(link)
    }

    render() {
        var { classes } = this.props;
        
        return <div className={classes.root} id="header">
            <AppBar className={classes.bar} elevation={0}>
                <Toolbar className={classes.wrapper}>
                    <h1 className={classes.title}>
                        <span className={classes.slategray}>Word</span>
                        <span className={classes.blue}>Crammer.</span>
                    </h1>
                    <div className={classes.buttoncontainer}>
                        {menu.map(m => 
                            <Scroll key={uuidv4()} to={m.scroll} smooth={true}>
                                {(m.onlyloggedin === this.props.loggedIn) && 
                                <div className={classes.button}
                                    onMouseEnter={() => this.props.appSpeak(m.section)}
                                    onClick={this.handleClick}
                                    color="primary"
                                    key={m.id}
                                    link={m.link}
                                    section={m.section}>
                                        {m.section}
                                </div>}
                            </Scroll>
                        )}
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    }
}

export default withStyles(styles)(Header);