import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReasonToCram from './ReasonToCram'
import { IconButton } from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { Link as Scroll } from 'react-scroll';

import reasons from '../static/reasons'

import useWindowPosition from '../hook/useWindowPosition';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    reasons: {
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "center",
            marginTop: "80px;"
        }
    },
    scroll: {
        display: "flex",
        flexDirection: "column", 
        justifyContent: "flex-start",
        alignItems: "center"
    },
    goDown: {
        fontSize: "4rem",
        color: "blue"
    }
}));

export default function ReasonsToCram() {
    const classes = useStyles();
    const checked = useWindowPosition('welcome');

    return (
        <div>
            <div className={classes.root} id="reasons-to-cram">
                <div className={classes.reasons}>
                    {reasons.map(r => 
                        <ReasonToCram 
                        key={r.title}
                        reason={r} 
                        checked={checked} />
                    )}
                </div>
                <div className={classes.scroll}>
                    <Scroll to="learn-vocab-now" 
                            smooth={true}>
                        <IconButton>
                            <ExpandMoreIcon 
                                className={classes.goDown} />
                        </IconButton>
                    </Scroll>
                </div>
            </div>
        </div>
    )
}