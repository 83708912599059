import React, {Component} from 'react';

import Header from './components/Header';
import Welcome from './components/Welcome';
import Footer from './components/Footer';
import ReasonsToCram from './components/ReasonsToCram';
import WhatToLearn from './components/WhatToLearn';
import LearnVocabNow from './components/LearnVocabNow';
import CramCarousel from './components/CramCarousel';
import NewCrammerForm from './components/NewCrammerForm';
import CramContainer from './components/CramContainer';
import Congratulations from './components/Congratulations';
import HistoryForm from './components/HistoryForm';
import BoringForm from './components/BoringForm';
import AnalysisForm from './components/AnalysisForm';
import EditSetForm from './components/EditSetForm';
import PickSetForm from './components/PickSetForm';
import PasswordForm from './components/PasswordForm';

//import Twilio from './apis/twilio.js';
import "./App.css";

import { withStyles, createStyles } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'

import speak, { getVoices } from './apis/tts.js';

const Axios = require("axios")

const { v4: uuidv4 } = require('uuid');

const styles = () => createStyles({
    root: {
        minHeight: "100vh",
        backgroundImage: `url(${require('./assets/bg.jpg')})`,
        backgroundSize: "100vw 800vh",
        backgroundRepeat: 'repeat',
    }
});

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            crammers : [],
            fullTopics: [],
            currentCrammer: null,
            loggedIn: false,
            streak: 0,
            lastdate: "",
            setSize: 30,
            currentSet: [],
            currentTopic: null,
            fullProgress: [],
            showCrammerCarousel: false, 
            showNewCrammerForm: false,
            showPasswordForm: true,
            showContentBox: false,
            showCramContainer: false,
            showCongratulations: false,
            showHistoryForm: false, 
            showBoringForm: false, 
            showSupportForm: false,
            showAnalysisForm: false,
            showPickSetForm: false,
            showEditSetForm: false,
            voices: null
        }
    }

    SQLQuery = async (SQLString) => {
      var url = 'https://database.wordcrammer.com/api/get?' + new URLSearchParams({SQLString})
      console.log(url)
      var response = await fetch(url, { method: 'GET' } )
      console.log("Response")
      console.log(response)
      var result = await response.json();
      console.log("Result")
      console.log(result)
      return result;
    }

    appSpeak = (whattosay, voice = "en-GB", rate=1, pitch=1) => {
        if (whattosay) {
            console.log("Speaking from App")
            console.log("Language: "+voice)
            speak(whattosay, this.state.voices, voice, rate, pitch)    
        } else {
            console.log("Asked to speak, but nothing to say")
        }
    }

    async componentDidMount() {

        /*var SQLString = `
            ALTER TABLE tblCrammers ADD COLUMN lastdate VARCHAR(12)`
        await this.SQLQuery(SQLString)
        console.log("done")*/
        
        var SQLString = `SELECT * FROM tblTopics ORDER BY topictitle ASC`
        var fullTopics = await this.SQLQuery(SQLString)
        
        SQLString = `SELECT * FROM tblCrammers`
        var crammers = await this.SQLQuery(SQLString)
        crammers = crammers.sort((a, b) => Number(b.score) - Number(a.score))
        
        var voices = await getVoices();
        speak("Welcome to Wordcrammer", voices, "en-GB")

        console.log(voices)

        this.setState({ crammers, voices, fullTopics } )    
        
    }

    todayNumber = () => {
        return Math.floor(Date.now()/(1000*60*60*24));
    }

    updateStreak = () => {
        var { currentCrammer } = this.state;
        var { lastdate, streak } = currentCrammer;
        var ago = this.todayNumber() - Number(lastdate);
        if (ago = 1) streak ++;
        if (ago > 1) streak = 1;
        currentCrammer.streak = streak;
        currentCrammer.lastdate = this.todayNumber();
        var SQLString = `UPDATE tblCrammers 
                     SET streak = '${streak}' 
                     WHERE username = '${currentCrammer.username}'`
        this.SQLQuery(SQLString)
        SQLString = `UPDATE tblCrammers 
                     SET lastdate = '${lastdate}' 
                     WHERE username = '${currentCrammer.username}'`
        this.SQLQuery(SQLString)
        this.setState({ currentCrammer,
                        showCramContainer : false,
                        showCongratulations : true })
    }

    setCurrentCrammer = async (username) => {

        var currentCrammer = this.state.crammers.find(c => c.username===username)
        var currentTopic = this.state.currentTopic;
        if (currentCrammer.topiccode) {
            currentTopic = this.state.fullTopics.find((t) => 
                t.topiccode===currentCrammer.topiccode)
        }

        this.setState({
            currentCrammer,
            currentTopic, 
            loggedIn: true,
            showCrammerCarousel: false
        },
        this.startCramming)
    }

    startCramming = async () => {

        var {currentCrammer, currentTopic, loggedIn} = this.state;

        this.updateStreak() 

        if (!currentCrammer) {
            return this.showDiv("CrammerCarousel")
        }
        if (!loggedIn) {
            speak(`Please log in`, 
                this.state.voices, "en-GB", 1, 1);
            return this.showDiv("PasswordForm")
        }
        if (!currentTopic) {
            speak(`Select a set to learn`, 
                this.state.voices, "en-GB", 1, 1);
            return this.showDiv("PickSetForm")
        }
        speak(`Enjoy your time cramming 
            ${currentTopic.topictitle.split("(")[0]}, 
            ${currentCrammer.firstname}!`, 
            this.state.voices, "en-GB", 1, 1);
        
        await this.generateSet()
        
        this.showDiv(
            (currentCrammer.score < 10) 
                ? "BoringForm"
                : "CramContainer")
    }

    generateSet = async () => {

        var { currentTopic, currentCrammer } = this.state;
        if (!currentTopic || !currentCrammer) return;

        console.log(`Generating set: ${currentTopic.topictitle}
                     for ${currentCrammer.alterego}`
        )

        var SQLString = `
            SELECT * FROM tblContent 
            WHERE topiccode = "${currentTopic.topiccode}"
            AND reported <> "1"`
        var items = await this.SQLQuery(SQLString);
        currentTopic.itemcount = items.length;
        
        SQLString = `
            SELECT * FROM tblProgress
            WHERE topiccode = "${currentTopic.topiccode}"
            AND username = "${this.state.currentCrammer.username}"
            AND CAST(corrects AS UNSIGNED) >= 1 
            ORDER BY question ASC`
            
        //ORDER BY CAST(attempts AS UNSIGNED) DESC`

        var fullProgress = await this.SQLQuery(SQLString);
        currentTopic.doneByMe = fullProgress.length;

        if (fullProgress.length===items.length) {
            return alert("You completed this set already.")
        }

        var currentSet = [];
        for (let i=0; i<items.length; i++) {
            let itemno = Math.floor(Math.random()*items.length)
            let item = items[itemno]
            const match = fullProgress.find(p => p.question===item.question)
            if (!match || (match && (match.attempts / match.corrects > 2.01))) {
                if (currentSet.length<this.state.setSize) {
                    currentSet.push({...item, attempts: 0, corrects: 0})
                }
            }
        }
        if (!currentSet.length) alert("Error")

        var currentItem = currentSet[0];
        this.setState( { currentSet, currentTopic, currentItem, fullProgress })
        return currentSet;
    }

    changeTopic = async (topicCode) => {
        var {currentCrammer} = this.state;
        if (currentCrammer) currentCrammer.topiccode = topicCode;
        var currentTopic = this.state.fullTopics
            .find(t => t.topiccode===topicCode)
        speak(currentTopic.topictitle, this.state.voices, "en-GB")
        this.setState( { currentTopic, currentCrammer, currentItem: null }, this.generateSet)
        if (currentCrammer) {
            var SQLString = `
                UPDATE tblCrammers SET topiccode = "${topicCode}" 
                WHERE username = "${currentCrammer.username}"`
            this.SQLQuery(SQLString)
        }
    }

    deleteCrammer = async (username) => {
        var SQLString = `DELETE FROM tblCrammers
            WHERE username="${username}"`
        await this.SQLQuery(SQLString);
        var crammers = this.state.crammers
                .filter(c => c.username !== username)
        this.setState( { crammers,
                         currentCrammer : null,
                         loggedIn: false })
    }

    addCrammer = async (newCrammer) => {
        newCrammer.key = uuidv4();
        var currentCrammer = this.state.currentCrammer;
        let attempts = (currentCrammer) ? currentCrammer.attempts : "0";
        let corrects = (currentCrammer) ? currentCrammer.corrects : "0";
        let score = (currentCrammer) ? currentCrammer.score : "0";
        let topiccode = (currentCrammer) ? currentCrammer.topiccode : "0";
        var SQLString = `DELETE FROM tblCrammers
            WHERE username="${newCrammer.username}"`
        await this.SQLQuery(SQLString);    
        SQLString = `INSERT INTO tblCrammers
            (username, firstname, alterego, password,
                emailaddress, mobile, topiccode,
                attempts, corrects, score)
            VALUES
            ('${newCrammer.username}', 
            '${newCrammer.firstname}', 
            '${newCrammer.alterego}', 
            '${newCrammer.password}', 
            '${newCrammer.email}', 
            '${newCrammer.mobile}',
            '${topiccode}',
            '${attempts}', 
            '${corrects}', 
            '${score}')`

        await this.SQLQuery(SQLString);
        console.log("Old crammers")
        console.log(this.state.crammers)
        console.log("Adding crammer:")
        console.log(newCrammer)
        var crammers = this.state.crammers
                .filter(c => c.username !== newCrammer.username)
        crammers.push(newCrammer)
        console.log("THis is the new ones")
        console.log(crammers)
        this.setState( { 
            crammers, 
            currentCrammer: newCrammer, 
            showNewCrammerForm: false,
            loggedIn: true,
            showPickSetForm: true
        } )
    }

    updateProgress = async (currentItem, bcorrect) => {
        var { currentCrammer, currentSet, currentTopic } = this.state;
        
        for (var item of currentSet) {
            if (item.question === currentItem.question) {
                item.attempts += 1;
                item.corrects = bcorrect ? item.corrects + 1 : item.corrects - 1;
                if (item.corrects === 1) currentTopic.doneByMe++ ;
                currentItem = item;
            }
        }

        currentCrammer.attempts = +Number(currentCrammer.attempts) + 1;

        if (bcorrect) {
            currentCrammer.score = +Number(currentCrammer.score) + currentItem.question.length;
            currentCrammer.corrects = +Number(currentCrammer.corrects) + 1;
        }

        var SQLString = 
            `DELETE FROM tblProgress WHERE 
             username="${currentCrammer.username}" AND
             topiccode="${currentItem.topiccode}" AND 
             question="${currentItem.question}"`;
        await this.SQLQuery(SQLString);

        SQLString = 
            `INSERT INTO tblProgress 
             (username, topiccode, question, attempts, corrects) VALUES
             ("${currentCrammer.username}", 
             "${currentItem.topiccode}", 
             "${currentItem.question}", 
             "${currentItem.attempts}", 
             "${currentItem.corrects}")`;
        await this.SQLQuery(SQLString);

        SQLString = 
            `UPDATE tblCrammers 
             SET attempts = "${+currentCrammer.attempts}"
             WHERE username = "${currentCrammer.username}"`;
        await this.SQLQuery(SQLString);

        if (bcorrect) {
            currentTopic.doneByMe = (currentTopic.doneByMe + 1)
            SQLString = 
                `UPDATE tblCrammers 
                 SET corrects = "${+currentCrammer.corrects}"
                 WHERE username = "${currentCrammer.username}"`;
            await this.SQLQuery(SQLString);
            SQLString = 
                `UPDATE tblCrammers 
                 SET score = "${+currentCrammer.score}"
                 WHERE username = "${currentCrammer.username}"`;
            await this.SQLQuery(SQLString);
        }

        SQLString = `SELECT * FROM tblCrammers`;
        var crammers = await this.SQLQuery(SQLString);
        crammers.sort((a,b) => Number(b.score) - Number(a.score))

        this.setState({ currentSet, currentTopic, currentCrammer, crammers })
    }

    congratulate = () => {
        this.updateStreak();
        this.setState({ showCramContainer : false,
                        showCongratulations : true })
    }

    showDiv = (divName) => {
        console.log(divName)
        var forms = ["NewCrammerForm",
                     "CrammerCarousel", 
                     "PasswordForm", 
                     "Congratulations",
                     "ContentBox",
                     "CramContainer",
                     "HistoryForm",
                     "BoringForm",
                     "SupportForm",
                     "AnalysisForm",
                     "EditSetForm",
                     "PickSetForm"]
        var s = {};
        for (var f of forms) {
            s["show"+f] = (f===divName)
        }
        this.setState(s);
    }

    loadSetData = async (topiccode) => {
        var SQLString = `
            SELECT * FROM tblContent WHERE topiccode="${topiccode}"`
        var result = await this.SQLQuery(SQLString);

        var setdata = "";
        for (var item of result) {
            setdata += item.question + "|" + item.answer
            setdata += "\n"
        }
        SQLString = `
            SELECT * FROM tblTopics WHERE topiccode="${topiccode}"`
        result = await this.SQLQuery(SQLString);
        var topic = result[0]
        return { topic, setdata }
    }

    saveSetData = async (wordset) => {
        var SQLString = `
            DELETE FROM tblContent WHERE 
                topiccode="${wordset.topiccode}"`
        await this.SQLQuery(SQLString);
        var setdata = wordset.setdata.split("\n")
        
        SQLString = `
            DELETE FROM tblTopics WHERE 
                topiccode="${wordset.topiccode}";`
        await this.SQLQuery(SQLString);
        
        SQLString = `
        INSERT INTO tblTopics 
            (topiccode, topictitle, setimage, 
                voice, description)
            VALUES 
            ("${wordset.topiccode}",
             "${wordset.topictitle}",
             "${wordset.setimage}",
             "${wordset.voice}",
             "${wordset.description}");`
        await this.SQLQuery(SQLString);

        var c;
        var beginning = `
            INSERT INTO tblContent
                (topiccode, questiontype, question, 
                    answer, reported)
                VALUES `;
        var additional = "";
        var i = 0;
        for (var item of setdata) {
            i++;
            c = item.split("|")
            if (c.length===1) c = c[0].split("*")
            if (c.length===2) {
                    if (additional) additional += ","
                    additional +=
                        `("${wordset.topiccode}",
                        "translate",
                        "${c[0].substring(0, 35).split("ʹ").join("'")}",
                        "${c[1].substring(0, 35).split("ʹ").join("'")}",
                        "0")`
                if (i > 5) {
                    this.SQLQuery(beginning + additional);
                    additional = "";
                    i=0;
                }
            }
        }
        if (additional) this.SQLQuery(beginning + additional);
    }

    deleteSet = async (topiccode) => {
        var SQLString = `
            DELETE FROM tblContent WHERE topiccode="${topiccode}"`
        await this.SQLQuery(SQLString);
        
        SQLString = `
            DELETE FROM tblTopics WHERE topiccode="${topiccode}";`
        await this.SQLQuery(SQLString);
    }

    hideIntro = () => {
        this.setState( { 
            intro: false, 
            showPasswordForm: true 
        })
    }

    reportWord = async (currentItem) => {
        var SQLString = `
            UPDATE tblContent SET reported = "1"
            WHERE topiccode="${currentItem.topiccode}"
            AND question="${currentItem.question}"`
        await this.SQLQuery(SQLString)
        var currentSet = this.state.currentSet;
        currentSet.forEach(i => {
            if (i.topiccode===currentItem.topiccode && 
                i.question===currentItem.question) {
                    i.reported="1";
            }
        })
        console.log(`Item ${currentItem.question} reported`)
    }

    handleNavbarClick = async (link) => {
        switch(link) {
            case "history":
                this.showDiv("HistoryForm")
            break;
            case "support":
                this.showDiv("SupportForm")
            break;
            case "adduser":
                this.setState({
                    currentCrammer: null,
                    loggedIn: false,
                },
                    this.showDiv("NewCrammerForm")
                )
            break;
            case "login":
                this.showDiv("PasswordForm")
            break;
            case "editset":
                this.showDiv("EditSetForm")
            break;
            case "pickset":
                this.showDiv("PickSetForm")
            break;
            case "start":
                this.startCramming();
            break;
            case "request":
                this.showDiv("SupportForm")
            break;
            case "analyseprogress":
                var SQLString = `
                SELECT * FROM tblProgress
                INNER JOIN tblContent ON
                tblProgress.question=tblContent.question 
                AND tblProgress.topiccode=tblContent.topiccode 
                AND tblProgress.username = "${this.state.currentCrammer.username}"
                AND CAST(tblProgress.corrects AS UNSIGNED) >= 2
                ORDER BY tblContent.topiccode ASC, 
                (CAST(tblProgress.attempts AS UNSIGNED)/
                CAST(tblProgress.corrects AS UNSIGNED)) 
                DESC`
                var fullProgress = await this.SQLQuery(SQLString);
                this.setState( { fullProgress } )
                setTimeout(() => {this.showDiv("AnalysisForm")}, 1000)
            break;
            default : 
          } 
    
        }

    render() {

        const { classes } = this.props;
        
        return (
            <div className={classes.root}>
                <div className="mainpanel">
                    <CssBaseline />
                    <Header 
                        loggedIn={this.state.loggedIn}
                        showDiv={this.showDiv}
                        appSpeak={this.appSpeak}
                        handleNavbarClick={this.handleNavbarClick} />
                    <CramCarousel 
                        setCurrentCrammer={this.setCurrentCrammer}
                        startCramming={this.startCramming}
                        appSpeak={this.appSpeak}
                        showDiv={this.showDiv}
                        crammers={this.state.crammers}
                        fullTopics={this.state.fullTopics}
                        />
                    <Welcome />
                    <Footer
                        currentCrammer={this.state.currentCrammer}
                        currentTopic={this.state.currentTopic}
                        showDiv={this.showDiv} />
                    <ReasonsToCram />
                    <div id="about">
                        <LearnVocabNow 
                            hideIntro={this.hideIntro}
                        />
                    </div>
                    <WhatToLearn />
                    <PasswordForm
                        showDiv={this.showDiv}
                        appSpeak={this.appSpeak}
                        startCramming={this.startCramming}
                        setCurrentCrammer={this.setCurrentCrammer}
                        changeTopic={this.changeTopic}
                        voices={this.state.voices}
                        currentTopic={this.state.currentTopic}
                        fullTopics={this.state.fullTopics}
                        currentCrammer={this.state.currentCrammer}
                        crammers={this.state.crammers} 
                    />
                    <div id="history">
                        <HistoryForm />
                    </div>
                    <div id="actionform" className={classes.root}>
                        {this.state.showNewCrammerForm &&
                        <NewCrammerForm
                            showDiv= {this.showDiv}
                            currentCrammer={this.state.currentCrammer}
                            fullTopics={this.state.fullTopics}
                            deleteCrammer={this.deleteCrammer}
                            addCrammer={this.addCrammer} 
                        />}
                        <div id="pickset">
                            {this.state.showPickSetForm &&
                            <PickSetForm
                                showDiv={this.showDiv}
                                appSpeak={this.appSpeak}
                                currentCrammer={this.state.currentCrammer}
                                changeTopic={this.changeTopic}
                                startCramming={this.startCramming}
                                fullTopics={this.state.fullTopics} 
                            />}
                        </div>
                        {this.state.showBoringForm &&
                        <BoringForm
                            showDiv={this.showDiv}
                        />}
                        {this.state.showEditSetForm &&
                        <EditSetForm
                            showDiv={this.showDiv}
                            fullTopics={this.state.fullTopics}
                            voices={this.state.voices}
                            loadSetData={this.loadSetData}
                            saveSetData={this.saveSetData}
                            deleteSet={this.deleteSet} 
                        />}
                        {this.state.showAnalysisForm &&
                        <AnalysisForm 
                            fullProgress={this.state.fullProgress}
                            fullTopics={this.state.fullTopics}
                            voices={this.state.voices}
                            currentCrammer={this.state.currentCrammer}
                            showDiv={this.showDiv}
                        />}
                        {this.state.showCramContainer &&
                        <CramContainer 
                            currentCrammer={this.state.currentCrammer}
                            fullProgress={this.state.fullProgress}
                            currentSet={this.state.currentSet}
                            setSize={this.state.setSize}
                            appSpeak={this.appSpeak}
                            voices={this.state.voices}
                            crammers={this.state.crammers}
                            congratulate={this.congratulate}
                            updateProgress={this.updateProgress}
                            startCramming={this.startCramming}
                            reportWord={this.reportWord}
                            currentTopic={this.state.currentTopic} />
                        }
                        {this.state.showCongratulations &&
                        <Congratulations 
                            currentCrammer={this.state.currentCrammer}
                            startCramming={this.startCramming}
                            currentTopic={this.state.currentTopic}
                            currentSet={this.state.currentSet} />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(App);
