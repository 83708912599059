import {Component} from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
    root: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            alignItems: "flex-end",
        }
    },
    title: {
        marginBottom: "10px",
        textAlign: "center",
        color: "black",
        fontFamily: "Nunito",
        fontSize: "3.0rem",
        fontWeight: "bold",
        [theme.breakpoints.down('sm')]: {
            fontSize: "2rem"
        }
    },
    itemsummary: {
        fontSize: "1rem",
    },
    description: {
        width: "70%",
        textAlign: "center",
        marginBottom: "25px",
        fontSize: "1rem",
        [theme.breakpoints.down('sm')]: {
            fontSize: "1rem",
            display: "none"
        }
    },
    red: {
        color: "red"
    },
    left: {
        width: "20%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRight: "1px dotted rgb(169, 199, 209)",
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    },
    right: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            marginBottom: "87px",
        }
    },
    topflagcontainer: {
        width: "100px",
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    topflag: {
        fontSize: "45px",
        transition: "0.5s ease all",
        "&hover": {
            cursor: "pointer",
            transform: "scale(0.9) translate(5px, 5px)",
        }
    },
    flagcomment: {
        textAlign: "center",
        fontSize: "13px",
    },
    peercontainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        borderRight: "1px solid darkblue",
        maxHeight: "500px",
    },
    peerme: {
        padding: "5px",
        fontSize: "1rem",
        color: "white",
        backgroundColor: "blue",
    },
    peer: {
        padding: "5px",
        fontSize: "1rem",
        color: "black",
    },
    boxes: {
        width: "60%",
        maxWidth: "350px",
        margin: "15px",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        [theme.breakpoints.down('sm')]: {
            width: "170px"
        }
    },
    boxnull: { width: "15px", height: "15px", 
           border: "1px solid black", margin: "4px",
           backgroundColor: "transparent",
           [theme.breakpoints.down('sm')]: {
                width: "12px", height: "12px", margin: "2px"
           }
    },
    boxred: { width: "15px", height: "15px", 
            border: "1px solid black", margin: "4px",
            backgroundColor: "red",
            [theme.breakpoints.down('sm')]: {
                width: "12px", height: "12px", margin: "2px"
            }
    },
    boxpink: { width: "15px", height: "15px", 
            border: "1px solid black", margin: "4px",
            backgroundColor: "pink",
            [theme.breakpoints.down('sm')]: {
                width: "12px", height: "12px", margin: "2px"
            }
    },
    box0: { width: "15px", height: "15px", 
            border: "1px solid black", margin: "4px",
            backgroundColor: "transparent",
            [theme.breakpoints.down('sm')]: {
                width: "12px", height: "12px", margin: "2px"
            }
    },
    boxdone: { display: "none" },
    questioncontainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "20px",
    },
    question: {
        fontSize: "2.5rem",
        fontWeight: "bold",
        fontStyle: "italic",
        [theme.breakpoints.down('sm')]: {
            fontSize: "1.7rem"
        }
    },
    inputcontainer: {
        position: "relative",
        width: "100%",
        height: "90px",
        display: "flex",
        justifyContent: "center",
        paddingTop: "20px",
        [theme.breakpoints.down('sm')]: {
            width: "80%",
            height: "60px",
            paddingTop: "10px"
        }
    },
    input: {
        position: "absolute",
        zIndex: 100,
        width: "500px",
        padding: "10px",
        fontSize: "1.5rem",
        borderRadius: "25px",
        textAlign: "center",
        backgroundColor: "white",
        transition: "0.5s ease all",
        [theme.breakpoints.down('sm')]: {
            width: "300px",
            height: "35px",
            padding: "4px",
            fontSize: "1.2rem",
            borderRadius: "15px"
        }
    },
    answer: {
        textAlign: "center",
        padding: "3px",
        borderRadius: "25px",
        margin: "0px",
        fontSize: "24px",
        fontWeight: "bold",
        transition: "0.5s ease all",
        [theme.breakpoints.down('sm')]: {
            width: "300px",
            height: "35px",
            padding: "4px",
            fontSize: "1.2rem",
            borderRadius: "15px"        }
    },
    button: {
        marginLeft: "20px",
        padding: "1px 4px 1px 4px",
        borderRadius: "5px",
        fontSize: "10px",
        backgroundColor: "lightgray",
        border: "2px solid darkgray",
        color: "black",
        transition: "0.5s ease all",
    },
    reported: {
        marginLeft: "20px",
        padding: "2px 4px 1px 4px",
        borderRadius: "5px",
        fontSize: "14px",
        color: "rgb(219, 141, 141)",
        [theme.breakpoints.down('sm')]: {
            marginLeft: "15px",
            padding: "2px 2px 1px 2px",
            fontSize: "10px",
        }
    },
    flag: {
        position: "absolute",
        top: "0",
        left: "20px",
    },
    keyboardtips: {
        width: "100%",
        textAlign: "center",
        fontSize: "16px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "13px"
        }
    },
    reveal: {
        position: "relative",
        zIndex: 200,
        width: "510px",
        padding: "6px",
        color: "white",
        fontWeight: 700,
        textAlign: "center",
        fontSize: "2rem",
        borderRadius: "25px",
        backgroundColor: "darkgray",
        "&hover": {
            backgroundColor: "black",
            cursor: "pointer"
        },
        [theme.breakpoints.down('sm')]: {
            width: "300px",
            height: "35px",
            padding: "4px",
            fontSize: "1.2rem",
            borderRadius: "15px"        }
    },
    hideAnswer: {
        opacity: "0",
        padding: "0",
        margin: "0",
        height: "0",
    },
    comment: {
        fontSize: "28px",
        fontWeight: "bold",
    },
    buttoncontainer: {
        margin: "20px",
    }
}));


var char = [
    ["/a", "á"],    ["/A", "Á"],
    ["/e", "é"],    ["/E", "É"],
    ["/i", "í"],    ["/I", "Í"],
    ["/o", "ó"],    ["/O", "Ó"],
    ["/u", "ú"],    ["/U", "Ú"],
    ["\\a", "à"],   ["\\A", "À"],
    ["\\e", "è"],   ["\\E", "È"],
    ["\\i", "ì"],   ["\\I", "Ì"],
    ["\\o", "ò"],   ["\\O", "Ò"],
    ["\\u", "ù"],   ["\\U", "Ù"],
    [":a", "ä"],    [":A", "Ä"],
    [":e", "ë"],    [":E", "Ë"],
    [":i", "ï"],    [":I", "Ï"],
    [":o", "ö"],    [":O", "Ö"],
    [":u", "ü"],    [":U", "Ü"],
    ["^a", "â"],    ["^A", "Â"],
    ["^e", "ê"],    ["^E", "Ê"],
    ["^i", "î"],    ["^I", "Î"],
    ["^o", "ô"],    ["^O", "Ô"],
    ["^u", "û"],    ["^U", "Û"],
    ["/s", "ß"],    
    ["|o", "ø"],    ["|O", "Ø"],
    ["0a", "å"],    ["0A", "Å"],
    ["=e", "æ"],    ["=E", "Æ"],
    ["=a", "æ"],    ["=A", "Æ"],
    ["=o", "œ"],    ["=O", "Œ"],
    ["/?", "¿"],    ["/!", "¡"],
    ["/G", "Ğ"],    ["/g", "ğ"],
    ["/s", "ş"],    ["/S", "Ş"],
    ["/c", "ç"],    ["/C", "Ç"],
    ["*I", "İ"],    ["*i", "ı"],
    ["=n", "ñ"],    ["=N", "Ñ"]
]

var flags = [["🇫🇷", "FR"], ["🇬🇧", "GB"], ["🇪🇸", "ES"],
["🇳🇱", "NL"], ["🇺🇦", "UK"], ["🇹🇷", "TR"],
["🇩🇪", "DE"], ["🇩🇰", "DK"], ["🇸🇪", "SE"],
["🇸🇯", "NO"], ["🇮🇹", "IT"]]

class CramContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentItem : this.props.currentSet[0],
            attemptsInSession : 0,
            showIntroduction: true,
            givenAnswer: "",
            revealAnswer: false,
            speaking: false,
            currentlyReading: "",
            wordReported: false
        }
    }

    componentDidMount() {
        this.proposeNext();
    }

    proposeNext = () => {
        var itemsToGo = 
            this.props.currentSet.reduce(
                (total, i) => total += (i.corrects<1 && !+i.reported), 1, 0) - 1
        if (itemsToGo===0) {
            this.props.congratulate();
        } else {
            do {
                var bok = true;
                var itemNumber = Math.floor(Math.random()*this.props.setSize)
                var currentItem = this.props.currentSet[itemNumber];
                if (currentItem.corrects >= 1) bok = false;
                if (+currentItem.reported) bok=false;
                if ((currentItem===this.state.currentItem) &&
                   (itemsToGo!==1)) bok = false;
            } while (!bok)
            var wordReported = (currentItem.reported==="1")
            this.setState({ currentItem, 
                            wordReported,
                            revealAnswer: false })
        }
    }

    handleKeyUp = (e) => {
        for (var c of char) {
            e.target.value = e.target.value.replace(c[0], c[1])
        }
        this.setState( { givenAnswer: e.target.value } );
        if (e.key === 'Enter') {
            this.setState( { 
                showIntroduction: true,
                attemptsInSession : this.state.attemptsInSession + 1 
            })
            e.target.value = "";    
            if (!this.state.revealAnswer) {
                return this.revealAnswer();
            } else {
                return this.proposeNext();
            }
        }
        var currentItem = this.state.currentItem;
        var givenAnswer = e.target.value;
        if (givenAnswer.toLowerCase()===currentItem.answer.toLowerCase()) {
            var bcorrect = true;
            this.props.appSpeak(currentItem.answer, this.props.currentTopic.voice)
            this.props.updateProgress(currentItem, bcorrect);
            e.target.value="";
            this.proposeNext();
        }
    }

    revealAnswer = () => {
        var attemptsInSession = this.state.attemptsInSession + 1;
        var bcorrect = false;
        this.props.updateProgress(this.state.currentItem, bcorrect);
        this.props.appSpeak(this.state.currentItem.answer, this.props.currentTopic.voice)
        this.setState({ 
            revealAnswer : true,
            attemptsInSession })
    }

    handleClickOnAnswer = () => {
        this.props.appSpeak(this.state.currentItem.answer, this.props.currentTopic.voice)
        setTimeout(() => this.setState({ revealAnswer: false }), 3000);
    }

    handleVerification = () => {
        var attemptsInSession = this.state.attemptsInSession + 1;
        this.setState( { attemptsInSession })
        this.proposeNext()
    }

    toggleSpeakSet = async () => {
        if (this.state.speaking) {
            this.stopSpeaking()
        } else {
            this.speakCurrentSet()
        }
    }

    stopSpeaking = async () => {
        this.setState({ speaking: false })
    }

    speakCurrentSet = async () => {

        this.setState({ speaking : true })

        var ssu = new SpeechSynthesisUtterance();

        var voice0 = this.props.voices.find(v => 
            v.lang === "en-GB"
        )
        var voice1 = this.props.voices.find(v => 
            v.lang === this.props.currentTopic.voice 
        )
        
        var series = [];
        for (var item of this.props.currentSet) {
            series.push(item.question)
            series.push(item.answer)
            series.push(item.question)
            series.push(item.answer)
            series.push(item.question)
            series.push(item.answer)
        }
        const speak = (series, index) => {
            if(index >= series.length) return;
            ssu.voice = (index % 2 === 0 ? voice0 : voice1)
            if (index % 6 === 0) {
                this.setState({ currentlyReading : series[index+1] });
            }
            ssu.text = series[index];
            ssu.rate = (index % 2 === 0) ? 1 : 0.7;
            ssu.onend = () => {
                if (!this.state.speaking) return;
                speak(series, ++index);
            };
            var pause = (index % 6 === 0) ? 2000 : 500;
            
            setTimeout(() => window.speechSynthesis.speak(ssu), pause);
        } 

        speak(series, 0)
        
    }

    handleReportButton = async () => {
        this.props.reportWord(this.state.currentItem)
        this.setState({ wordReported: true })
    }

    render() {

        const { classes } = this.props;

        if (!this.props.currentCrammer) {
            console.log("No current crammer");
            return null;
        }
        if (!this.props.currentSet) {
            console.log("No current set");
            return null;
        }
        
        var currentTopic = this.props.currentTopic;
        var voice = currentTopic.voice;
        var country = (voice) ? voice.split("-")[1] : "";
        var combo = flags.find(f => f[1]===country)
        var flag = (combo) ? combo[0] : "";
        var answer = (this.state.currentItem) ? this.state.currentItem.answer : "";
        var keyboardtips = "";
        for (var l of answer) {
            for (var c of char) {
                if (l===c[1]) {
                    if (keyboardtips) keyboardtips += ", "
                    keyboardtips += `"${c[0]}" for "${c[1]}"`
                }
            }
        }
        if (keyboardtips) keyboardtips = ` (tip: use ${keyboardtips})`
        var currentCrammer = this.props.currentCrammer;
        var currentQuestion;
        currentQuestion = (this.state.currentItem.question)
        var itemsToGo = this.props.currentSet.reduce(
            (total, i) => total += (i.corrects!==2 && !+i.reported), 1, 0) -1;
        var introduction = null;
        if (this.state.showIntroduction) {
            introduction = (
                <div className={classes.description}>
                    This is your opportunity to improve your score, {currentCrammer.firstname}!
                    Please answer each of the questions in the least number of attempts.
                    You have {itemsToGo} items to go.
                </div>
            )    
        }
        var peers = this.props.crammers.map(c => {
            var peerClass = (c.username===currentCrammer.username)
                ? classes.peerme
                : classes.peer;
            if (c.score>1000) {return <div className={peerClass}
                                       key={c.username}>
                                       {`${c.username} (${c.score})`}
                                  </div>
                           } else {
                               return null;
                           }
            }
        )
        var topTitle;
        if (this.state.speaking) {
            topTitle = (this.state.currentlyReading)
        } else {
            topTitle = <div className={classes.title}>
                <div>{this.props.currentTopic.topictitle}</div>
                <div className={classes.itemsummary}>
                    (you have done {this.props.currentTopic.doneByMe} of {this.props.currentTopic.itemcount} items)
                </div>
            </div>
        }

        return (
            <div className={classes.root}>
                <div className={classes.left}>
                    <div className={classes.topflagcontainer}>
                        <div className={classes.topflag}
                            onClick={this.toggleSpeakSet}>
                                {(!this.state.speaking) && flag}
                        </div>
                        <div className={classes.flagcomment}>
                            click on flag
                        </div>
                    </div>
                    {(!this.state.speaking) && 
                        <div className={classes.peercontainer}>
                        {peers}
                    </div>}
                </div>
                <div className={classes.right}>
                    <div className={classes.title}>
                        {topTitle}
                    </div>
                    {(!this.state.speaking) && introduction}
                    {(!this.state.speaking) && 
                        <div className={classes.boxes}>
                        {
                            this.props.currentSet.map((i, x) => {
                                var boxclass = classes.boxnull;
                                if (i.corrects < -1) boxclass = classes.boxred
                                if (i.corrects === -1) boxclass = classes.boxpink
                                if (i.corrects === 0) boxclass = classes.box0
                                if (i.corrects > 0) boxclass = classes.boxdone
                                return <div key={x} className={boxclass}></div>
                            })
                        }
                    </div>}
                    {!this.state.speaking && 
                        <div className={classes.questioncontainer}>
                            <div className={classes.question}>
                                "{currentQuestion}"
                            </div>
                            {(this.state.wordReported)
                            ?  <div className={classes.reported}>
                                        Reported
                               </div>
                            :  <div className={classes.button}
                                    onClick={this.handleReportButton}>
                                        Report error
                               </div>}
                        </div>}
                    {!this.state.speaking && 
                        <div className={classes.inputcontainer}>
                            {this.state.revealAnswer && 
                                <div className={classes.red}
                                    onClick={this.handleClickOnAnswer}>
                                    <div className={classes.reveal}>
                                        <div className={classes.flag}>{flag}</div>
                                            <div>{answer}</div>
                                    </div>
                                    <div className={classes.keyboardtips}>
                                        {keyboardtips}
                                    </div>
                                </div> 
                            }
                            <input autoFocus 
                                className={classes.input}
                                autoComplete="off"
                                name="givenAnswer" 
                                onKeyUp={this.handleKeyUp} 
                                type="text" />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(CramContainer);