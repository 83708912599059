import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Collapse } from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import { Link as Scroll } from 'react-scroll';

const useStyles = makeStyles((theme) => ({
    root: {
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        marginTop: "30vh",
        [theme.breakpoints.down('sm')]: {
            marginTop: "23vh"
        }
    },
    title: {
        color: "black",
        fontFamily: "Nunito",
        fontSize: "4rem",
        [theme.breakpoints.down('sm')]: {
            fontSize: "2.4rem"
        }
    },
    slategray: {
        color: "slategray"
    },
    blue: {
        color: "blue"
    },
    subtitle: {
        color: "black",
        fontWeight: 800,
        marginTop: 20,
        fontFamily: "Nunito",
        fontSize: "1.5rem",
        [theme.breakpoints.down('sm')]: {
            fontSize: "1.2rem"
        }
    },
    container: {
        textAlign: "center"
    },
    goDown: {
        fontSize: "5rem",
        color: "blue",
        [theme.breakpoints.down('sm')]: {
            fontSize: "4rem"
        }
    },
    brands: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    brand: {
        width: "70px",
        height: "40px",
        margin: "20px"
    }
}));

export default function Welcome() {

    const classes = useStyles();
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        setChecked(true);
    }, [])

    var brandsimgs = [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtRVn64F7gndBQFrbGekhOL7sOxCwndI1CAA&usqp=CAU",
        "https://e7.pngegg.com/pngimages/911/399/png-clipart-cosmopolitan-logo-cosmopolitan-magazine-logo-fashion-publishing-others-miscellaneous-text.png",
        "https://w7.pngwing.com/pngs/732/34/png-transparent-logo-amazon-com-brand-flipkart-others-text-orange-logo-thumbnail.png",
        "https://www.freepnglogos.com/uploads/zara-logo-png/zara-men-long-sleeve-shirts-top-brands-techfilipino-0.png"
    ]

    var brands = null //brandsimgs.map(b => <img src={b} className={classes.brand} />)
    
    return <div className={classes.root} id="welcome">
        <Collapse in={checked} 
                  { ... (checked ? {timeout : 1000 } : {} )}
                  collapsedSize={50} >
            <div className={classes.container}>
                <div className={classes.title}>
                    <div>
                        <span>Welcome to<br/></span>
                        <span className={classes.slategray}>Word</span>
                        <span className={classes.blue}>Crammer.</span>
                    </div>
                    <div className={classes.subtitle}>
                        <span>Start Learning Like the Pros!</span>
                    </div>
                </div>
                <Scroll to="reasons-to-cram" smooth={true}>
                    <IconButton>
                        <ExpandMoreIcon className={classes.goDown} />
                    </IconButton>
                </Scroll>
            </div>
        </Collapse>
    </div>
}