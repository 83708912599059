import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import { makeStyles } from '@material-ui/core/styles';
import { Collapse } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      width: "340px",
      margin: "12px",
      [theme.breakpoints.down('sm')]: {
        display: "flex",
        width: "300px",
        height: "120px",
        margin: "4px",
      }
    },
    media: {
      height: 250,
      width: "100%",
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        height: "auto",
        width: "30%",
        flexBasis: "50%",
        zIndex: "99"
      }
    },
    content: {
      padding: 8,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      [theme.breakpoints.down('sm')]: {
        width: "100%",
        padding: 8
      }
    },
    title: {
      fontFamily: "Nunito",
      fontWeight: 1000, 
      fontSize: "1.5rem",
      color: "white",
      [theme.breakpoints.down('sm')]: {
        fontSize: "1rem",
        color: "white",
        zIndex: 100
      }
    },
    description: {
      height: "100px",
      fontFamily: "Nunito",
      fontSize: "1.3rem",
      color: "white",
      [theme.breakpoints.down('sm')]: {
        width: "100%",
        fontSize: "0.9rem",
      }
    }
}));

export default function ReasonToCram({ reason, checked }) {
  const classes = useStyles();
  return (
    <Collapse in={checked}
              { ... (checked ? { timeout : 500} : {} )}>
      <div className={classes.root}>
        <img
          className={classes.media}
          src={reason.imageUrl}
          alt=""
        />
        <div className={classes.content}>
          <div className={classes.title}>
            {reason.title}
          </div>
          <div className={classes.description}>
              {reason.description}
          </div>
        </div>
      </div>
    </Collapse>
  );
}