import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { IconButton, Collapse } from '@material-ui/core';
import PlayArrowIcon from "@material-ui/icons/PlayArrow"

import { Link as Scroll } from 'react-scroll';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
        width: "80vw",
        maxWidth: "1450px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    title: {
        textAlign: "center",
        color: "black",
        fontFamily: "Nunito",
        fontSize: "3.0rem",
        [theme.breakpoints.down('sm')]: {
            fontSize: "1.7rem"
        }
    },
    word: {
        color: "slategray"
    },
    crammer: {
        color: "blue"
    },
    sup: {
        fontSize: "1.2rem",
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.7rem"
        }
    },
    subtitle: {
        textAlign: "center",
        width: "80%",
        color: "black",
        marginTop: 5,
        fontFamily: "Nunito",
        fontSize: "1.6rem",
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.95rem"
        } 
    },
    goDown: {
        fontSize: "5rem",
        color: "blue",
        [theme.breakpoints.down('sm')]: {
            fontSize: "3rem"
        }
    }
}));

export default function LearnVocabNow() {

    const classes = useStyles();

    return (
        <div className={classes.root} id="learn-vocab-now">
            <div className={classes.title}>
                <span className={classes.word}>Word</span>
                <span className={classes.crammer}>Crammer</span>
                <sup className={classes.sup}>TM</sup>
                <span> expands your brain.<br/></span>
            </div>
            <div className={classes.subtitle}>
                <p><span>The sets are designed to adapt to your level and 
                    follow your progress. With the use of our proprietary </span>
                    <span className={classes.crammer}>Artificial Intelligence</span> 
                    <span> you will feel you study as if your tutor is watching you.</span></p>
                <p>Select your language - we recommend you commit to learn 3 sets per day.</p>
                <p>With 20-30 minutes per day you will soon feel the progress.</p>
                <p>Word<span className={classes.crammer}>Crammer</span> is, and will always be, <b>free of charge!</b></p>
            </div>
            <Scroll to="what-to-learn" smooth={true}>
                <IconButton>
                    <PlayArrowIcon 
                        className={classes.goDown} />
                </IconButton>
            </Scroll>
        </div>
    )
}