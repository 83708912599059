import React, {Component} from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles'

import { Button } from '@mui/material';

const styles = createStyles((theme) => ({
    root: {
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    title: {
        textAlign: "center",
        color: "black",
        fontFamily: "Nunito",
        fontSize: "3.0rem",
        [theme.breakpoints.down('sm')]: {
            fontSize: "1.25rem",
        }
    },
    body: {
        width: "60%",
        fontSize: "1.3rem",
        margin: "0px 0 25px 0",
        textAlign: "center",
        [theme.breakpoints.down('sm')]: {
            width: "80%",
            fontSize: "0.9rem",
        }
    },
    button: {
        margin: "15px"
    }
}));

class BoringForm extends Component {

    handleClick = (e) => {
        this.props.showDiv("CramContainer")
    }

    render() {

        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.title}>
                    Your first time cramming!
                </div>
                <hr/>
                <div
                 className={classes.body}>
                    Congratulations - you decided to give it a go! 🚀 <br/><br/>
                    Please be aware, this may be boring in the beginning. 🥱 <br/><br/>
                    However as soon as you start repeating words, you will get it. 💡<br/><br/>
                    After 20 sets, you will notice your progress. 🙌 
                    You may not like the process, but you will love the efficiency! ❤️ <br/><br/>
                    It will be tough... but try to enjoy it anyway! 🎉 ;)
                </div>
                <div className={classes.button}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={this.handleClick}>
                            Proceed with my first set
                    </Button>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(BoringForm)