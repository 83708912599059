import {Component} from 'react';

import { withStyles, createStyles } from '@material-ui/core/styles'

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';

import './Spinner.css';

const { v4: uuidv4 } = require('uuid');

const styles = (theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        [theme.breakpoints.down('sm')]: {
          width: "300px",
          margin: "6px",
        }
    },
    title: {
        fontFamily: "Nunito",
        fontSize: "42px",
    },
    instruction: {
        fontSize: "20px",
        margin: "10px 0 0 0",
    },
    setinput: {
        width: "90vw",
        height: "60vh",
        display: "flex",
        color: "white",
        alignItems: "flex-start",
        justifyContent: "center",
        fontSize: "16px",
    },
    existing: {
        flexBasis: "400px",
        margin: "40px 0px",
        padding: "0px 0px 20px 0px",
        fontWeight: "bold",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        overflowY: "scroll",
    },
    topic: {
        width: "45%",
        padding: "2px 0 2px",
        margin: "4px",
        background: "rgb(23, 37, 51)",
        transition: "0.2s ease all",
        "&:hover": {
            background: "gb(116, 105, 128)",
            cursor: "pointer",
        }
    },
    topiccode: {
        fontSize: "18px",
    },
    itemcount: {
        fontSize: "15px",
        marginLeft: "5px",
    },
    topictitle: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontSize: "10px",
    },
    define: {
        display: "flex",
        width: "85%",
    },
    left: {
        flexBasis: "20%",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        alignItems: "center",
        padding: "40px 10px 0px 40px",
    },
    right: {
        display: "block",
        flexBasis: "80%",
        height: "100%",
        padding: "40px",
    },
    image: {
        width: "100%",
    },
    description: {
        fontSize: "16px",
        width: "100%",
    },
    buttonstack: {
        margin: "20px",
    },
    setdata: {
        flexBasis: "50%",
        flexGrow: "100",
        height: "440px",
        fontSize: "12px",
        margin: "40px 0 0 15px",
    }    
});

class EditSetForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            topiccode: "",
            topictitle: "",
            description: "",
            voice: "",
            setimage: "",
            setdata: "",
            savebuttondisabled: true,
            showSpinner: false
        }
    }

    loadSet = async(topiccode) => {
        this.setState({ showSpinner : true }, async () => {
            if (topiccode.length > 5) {
                var {topic, setdata} = await this.props.loadSetData(topiccode);
                this.setState({ ...topic, setdata })
            }
            this.handleSaveButton();
            this.setState({ showSpinner: false })    
        })
    }

    handleVoiceSelect = (e) => {
        this.setState({voice : e.target.value})
    }

    handleLoad = async () => {
        this.loadSet(this.state.topiccode);
    }

    handleClick = async (e) => {
        this.loadSet(e.target.getAttribute("topiccode"))
    }

    handleSave = async () => {
        if (this.state.topiccode.length > 5) {
            this.setState({ showSpinner : true }, async () => {
                await this.props.saveSetData(this.state)
                this.setState({ showSpinner : false })
            })
        }
    }

    handleDelete = async () => {
        if (this.state.topiccode.length > 5) {
            await this.props.deleteSet(this.state.topiccode)
        }
        this.setState({
            topiccode: "",
            topictitle: "",
            setimage: "",
            voice: "",
            description: "",
            setdata: "",
            savebuttondisabled: true
        })
    }

    handleChange = (e) => {
        if (e.target.name==="topiccode") {
            e.target.value = e.target.value.toUpperCase().substring(0,6)
        }
        if (e.target.name==="topictitle") {
            e.target.value = e.target.value.substring(0,30)
        }
        var state = this.state;
        state[e.target.name] = e.target.value;
        this.setState(state, this.handleSaveButton())
    }

    handleSaveButton() {
        var vis = 1;
        if (this.state.topiccode.length < 6) vis = 0;
        if (this.state.topictitle.length < 10) vis = 0;
        if (this.state.description.length < 10) vis = 0;
        if (this.state.setimage.length < 10) vis = 0;
        if (this.state.voice.length < 3) vis = 0;
        if (this.state.setimage.substring(0,4).toLowerCase()!=="http") vis = 0;
        if (this.state.setdata.indexOf("|") < 0) vis = 0;
        let state = this.state;
        state.savebuttondisabled = !vis;
        this.setState({ state })
    }

    render() {
        
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.title}>
                    Edit an Existing Set or Create a New Set
                </div>
                <hr/>
                <div className={classes.instruction}>
                    A set can have unlimited items to cram. Just add a topic code, a title, description and upload your list!
                </div>
                <div className={classes.setinput}>
                    <div className={classes.existing}>
                        {this.props.fullTopics.map(t => (
                            <div key={uuidv4()}
                                 topiccode={t.topiccode}
                                 className={classes.topic}
                                 onClick={this.handleClick}>
                                     <div
                                        topiccode={t.topiccode}
                                        >
                                        <span className={classes.topiccode}
                                            topiccode={t.topiccode}>
                                            {t.topiccode}
                                        </span>
                                        <span className={classes.itemcount}
                                            topiccode={t.topiccode}>
                                            ({t.itemcount})
                                        </span>
                                         
                                     </div>
                                     <div className={classes.topictitle}
                                        topiccode={t.topiccode}
                                        >
                                        {t.topictitle}
                                    </div>
                            </div>
                        ))}
                    </div>
                    <div className={classes.define}>
                        <div className={classes.left}>
                            <Stack spacing={2} direction="column">
                                <img 
                                    className={classes.image}
                                    src={this.state.setimage}/>
                                <Stack spacing={2} direction="column">
                                    <Button 
                                        variant="contained"
                                        onClick={this.handleLoad}>
                                            Load
                                    </Button>
                                    <Button 
                                        variant="contained"
                                        disabled={this.state.savebuttondisabled}
                                        onClick={this.handleSave}>
                                            Save
                                    </Button>
                                    <Button 
                                        variant="contained"
                                        onClick={this.handleDelete}>
                                            Delete
                                    </Button>
                                </Stack>
                                <TextField 
                                    multiline
                                    fullWidth
                                    name="description"
                                    value={this.state.description}
                                    onKeyUp={this.handleChange}
                                    onChange={this.handleChange}
                                    className={classes.description}
                                    rows="6"
                                    placeholder="Set description" />
                            </Stack>
                        </div>
                        <div className={classes.right}>
                            {this.state.showSpinner && <div className="spinner">
                                <div className="loader">
                                    <div className="inner one"></div>
                                    <div className="inner two"></div>
                                    <div className="inner three"></div>
                                </div>
                            </div>}
                            <Stack spacing={2} direction="column">
                                <Stack spacing={2} direction="row">
                                    <TextField
                                        fullWidth
                                        name="topiccode"
                                        value={this.state.topiccode}
                                        onKeyUp={this.handleChange}
                                        onChange={this.handleChange}
                                        autoComplete="off"
                                        className={classes.input}
                                        type="text"
                                        placeholder="Enter 6 letter topiccode" />
                                    <TextField
                                        fullWidth
                                        name="topictitle"
                                        value={this.state.topictitle}
                                        onKeyUp={this.handleChange}
                                        onChange={this.handleChange}
                                        autoComplete="off"
                                        className={classes.input}
                                        type="text"
                                        placeholder="Enter a set title" />
                                </Stack>
                                <FormControl className={classes.voiceselect}
                                    onChange={this.handleVoiceSelect}>
                                        <NativeSelect
                                            defaultValue={this.state.voice}
                                            inputProps={{
                                                name: "Select your Into Voice",
                                                id: "voiceselector"
                                            }}
                                            >
                                            {this.props.voices.map(v => {
                                                return <option
                                                    value={v.lang}
                                                    selected={v.lang===this.state.voice}
                                                    key={v.name}>
                                                        {v.name}
                                                </option>}
                                            )}
                                        </NativeSelect>
                                </FormControl>
                                <TextField
                                    name="setimage"
                                    onKeyUp={this.handleChange}
                                    onChange={this.handleChange}
                                    placeholder="URL of the set's image (starting with http)"
                                    fullWidth
                                    value={this.state.setimage}>
                                </TextField>
                                <TextField
                                    multiline
                                    fullWidth
                                    inputProps={{style: {fontSize: 12}}}
                                    name="setdata"
                                    value={this.state.setdata}
                                    onKeyUp={this.handleChange}
                                    onChange={this.handleChange}
                                    className={classes.setdata}
                                    rows="12"
                                    placeholder="Set data: single lines, in which words are separated by | or * &#10; For example, &#10;&#10; the beer|la cerveza &#10;&#10;or the cyclist*le cicliste &#10;&#10;Good luck!"/>
                            </Stack>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(EditSetForm);