import {Component} from 'react';

import { withStyles, createStyles } from '@material-ui/core/styles'

import { Link as Scroll } from 'react-scroll';
const { v4: uuidv4 } = require('uuid');

const styles = (theme) => createStyles({
    root: {
        position: "absolute",
        top: "50%",
        left: 30,
        transform: "translate(0, -50%)",
        overflowY: "none",
        [theme.breakpoints.down('sm')]: {
            height: "150px",
            overflowY: "scroll",
            top: "58%",
            left: "55%",
            width: "320px",
            transform: "translate(-40%, 20%)"
        }
    },
    person: {
        textAlign: "left",
        padding: "3px",
        transition: "0.5s ease all",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "35px",
        color: "gray",
        [theme.breakpoints.down('sm')]: {
            height: "25px"
        },
        "&:hover": {
            background: "sand",
            borderRadius: "5px",
            cursor: "pointer",
            color: "black",
            boxShadow: "5px 10px 12px gray"
        }
    },
    alterego: {
        fontSize: "1rem",
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.8rem"
        }
    },
    medal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1.2rem",
        width: "50px"
    },    
    details: {
        justifyContent: "left",
        width: "100%"
    },    
    result: {
        fontSize: "15px",
        color: "black"
    }
});

class CramCarousel extends Component {
    
    handleClick = (e) => {
        var username = e.target.getAttribute("username")
        if (!username) return;
        this.props.setCurrentCrammer(username);
        this.props.startCramming();
    }

    handleHoverPerson = (e) => {
        var textcontent = e.target.getAttribute("content");
        this.props.appSpeak(textcontent)
    }

    handleAddNew = () => {
        this.props.showDiv("CrammerForm")
    }

    render() {
        const { classes } = this.props;
        var {crammers} = this.props;
        var carousel = 
            <div className={classes.root}>
                {crammers.map((crammer, i) => {
                    var person = `
                        ${1+i}. ${crammer.alterego} (${+crammer.score})`
                    var medal = " "
                    if (i<3) medal = "🥉"
                    if (i<2) medal = "🥈"
                    if (i<1) medal = "🥇"
                    var hasScore = (crammer.score>0);
                    return (
                    hasScore && 
                    <Scroll key={uuidv4()} to="actionform" smooth={true}>
                        <div className={classes.person}
                            onMouseEnter={this.handleHoverPerson}
                            key={crammer.username}
                            username={crammer.username}
                            content={crammer.alterego}
                            onClick={this.handleClick}>
                            <div className={classes.medal}
                                username={crammer.username}
                                content={crammer.alterego}>
                                    {medal}
                            </div>
                            <div className={classes.alterego}
                                username={crammer.username}
                                content={crammer.alterego}>
                                    {person}
                            </div>
                        </div>
                    </Scroll>)
                })}
            </div>
        
        return (
            <div className={this.root}>
                {carousel}                    
            </div>
        )
    }
}

export default withStyles(styles)(CramCarousel);