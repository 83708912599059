import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { IconButton, Collapse } from '@material-ui/core';
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"

import { Link as Scroll } from 'react-scroll';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        marginTop: "300px",
        alignItems: "center"
    },
    title: {
        textAlign: "center",
        color: "black",
        fontFamily: "Nunito",
        fontSize: "3.0rem",
        [theme.breakpoints.down('sm')]: {
            fontSize: "1.5rem",
        }
    },
    slategray: {
        color: "slategray"
    },
    blue: {
        color: "blue"
    },
    sup: {
        fontSize: "1.2rem"
    },
    goDown: {
        fontSize: "5rem",
        color: "blue"
    },
    body: {
        width: "60%",
        fontSize: "1.3rem",
        margin: "0px 0 25px 0",
        textAlign: "center",
        [theme.breakpoints.down('sm')]: {
            width: "80%",
            fontSize: "1rem",
        }
    }
}));

export default function LearnVocabNow() {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                Learning is an <span className={classes.blue}>Art</span>!
            </div>
            <hr/>
            <div className={classes.body}>
                The best way to learn is by immersion. Just like children. Focus on curiosity.
                Cramming playfully, may just be the way to learn for you!<br/><br/>
                Some may find it boring, others addictive. You will like the efficiency!<br/><br/>
                Whichever your purpose, try to enjoy it anyway! ;)
            </div>
            <Scroll to="login" smooth={true}>
                <IconButton>
                    <KeyboardArrowUpIcon 
                        className={classes.goDown} />
                </IconButton>
            </Scroll>
        </div>
    )
}